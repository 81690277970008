/* Menu toggle button */
.menu-toggle {
    border: 1px solid rgba(255, 255, 255, .2);
    font-weight: bold;
    border-radius: 6px;
    padding: 0px 15px;
    font-size: 12px;
    background-color: #000;
    color: #fff;
    margin: 0px 15px;
}

/* Side menu container */
.side-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 150px;
    height: 100vh;
    background: linear-gradient(to right, #222, #000);
    color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    padding: 20px;
    animation: slideIn 0.3s ease-in-out;
    // border-left: 1px solid rgba(255, 255, 255, .1);
}

.side-menu-header {
    padding: 15px 0;
    text-align: right;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

/* Close button */
.close-menu {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
}

/* Accordion buttons */
.accordion-button {
    background-color: transparent;
    border: none;
    color: #fff;
    text-align: left;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
}

/* Accordion content */
.accordion-content {
    padding-left: 15px;
    margin-top: 5px;
}

.menu-list {
    list-style: none;
}

.menu-list li {
    padding: 15px 0;
    text-align: right;
}

.menu-list a {
    color: #fff;
    opacity: .5;
    text-decoration: none;
    text-align: right;
    font-weight: bold;
}

.menu-list a:hover {
    text-decoration: underline;
    opacity: 1;
}

.mobile-menu-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;

    .button-area {
        width: 250px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

#mobile-block {
    display: none; /* 默认隐藏 */
}

@media (max-width: 768px) {
    #mobile-block {
        display: block; /* 在手机界面显示 */
        width: 100%;
        height: 50px;
    }
}