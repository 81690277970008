// assets/styles/agent.scss

.agent-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    * {
        color: #222;
    }
}

.agent-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px; // Equivalent to theme.spacing(2)
    background-color: #fff;
    border-bottom: 1px solid #eee;

    .logo {
        height: 40px;
        width: auto;
        justify-content: center;
    }
}

.agent-content {

    flex-grow: 1;
    background-color: #0e1b31;
    padding: 30px 0;
    * {
        color: #999;
    }
}

.agent-footer {
    margin-top: auto;
    padding: 16px; // Equivalent to theme.spacing(2)
    text-align: center;
    background-color: #000;
    color: rgb(153, 153, 153) !important;
}

.agent-form {
    .button-group {
        width: 100%;

        .MuiButton-contained {
            background-color: #ec0029 !important;
            color: #fff !important;
        }

        .MuiButton-root {
            color: #ec0029;
            border-color: #ec0029;


            &:hover {
                background-color: rgba(236, 0, 41, 0.1);
                border-color: #ec0029;
            }
        }
    }

    .input {
        color: #222;
    }


}

.agent-textfield {
    .MuiOutlinedInput-root {
        color: black; // Text color for input
        border-color: #999; // Default border color

        // Styling the border
        fieldset {
            border-color: #999;
        }

        // Border color on hover
        &:hover fieldset {
            border-color: #666; // Darker shade on hover
        }

        // Border color when focused
        &.Mui-focused fieldset {
            border-color: #333; // Even darker shade on focus
        }
    }

    // Style the label
    .MuiInputLabel-root {
        color: #999; // Default label color

        // Change color on focus
        &.Mui-focused {
            color: #333; // Darker shade on focus
        }
    }
}

.agent-payment-info {
    color: #999;
    font-size: 12px;

    .MuiTypography-root {
        font-size: inherit; // Ensures Typography uses the parent font size
    }

    * {
        color: #999 !important;
    }
}

.menu-button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
        flex: 1;
        background: none !important;
        border: none;
        color: #b0b0b0;
        font-size: 16px;
        font-weight: bold;
        padding: 10px 0;
        cursor: pointer;
        position: relative;
        outline: none;

        &:hover {
            color: #b0b0b0;
        }

        &.active {
            color: #D14991;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: #D14991;
            }
        }
    }
}

.settlement-box {
    width: 100%;
    padding: 15px;
}

.settlement-info {
    padding: 15px 25px;

    * {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 576px) {

    /* .tabs {
       display: none;
     }*/
    .agent-form {
        max-width: 500px !important;
    }

    .settlement-box {
        max-width: 500px !important;

    }

}