.b2c-page {
    padding-top: 200px;
    padding-bottom: 200px;
}

@media (max-width: 768px) {
    .b2c-page {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}