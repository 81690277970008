// src/components/login.scss

// 示例样式，可以根据需要自定义
.login-container {
    padding: 20px;
}

.logo {
    margin-bottom: 16px;
    padding-right: 60px;
}

.button-container {
    margin-top: 20px;
}

.forgot-password {
    color: #007bff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}