.energy-rent-form {
    background-color: #0e1b31;
    padding: 15px;
    max-width: 600px;
    border: 1px solid #0E1B31;

    .transaction-count {
        margin-bottom: 30px;
    }

    .options-btn {
        background-color: inherit;
        color: #d14991;
        border: 1px solid #d14991;
        padding: 8px 16px;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }

    .mb_20 {
        margin-bottom: 20px;
    }

    // 提交按钮样式
    .submit {
        width: 100%;
        margin-top: 16px;
        font-weight: bold;
        color: #fff;
        background: linear-gradient(90deg, #edc4db, #eda7c8, #d14991, #6566a8, #1f69ad, #36b0d7, #38baed);
        border: none;
        padding: 8px 16px;
        cursor: pointer;
        transition: background 0.3s ease;

        &:hover {
            background: linear-gradient(90deg, #38baed, #36b0d7, #1f69ad, #6566a8, #d14991, #eda7c8, #edc4db);
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    // 激活状态样式
    .options-btn.active {
        background-color: #d14991 !important;
        color: #fff;

        &:hover {
            background-color: #d14991;
        }
    }

    input {
        color: white !important;

        &::placeholder {
            color: rgba(255, 255, 255, 0.6) !important; // 半透明白色的 placeholder
        }
    }

    // Typography 文字颜色设置为白色
    .MuiTypography-root {
        color: white !important;
    }

    // Material-UI 标签文字颜色设置
    .MuiFormLabel-root {
        color: white !important;
    }

    .MuiInputBase-root {
        color: white !important;
        border-color: rgba(255, 255, 255, 0.6) !important;
    }

    // 自定义复选框样式
    .MuiCheckbox-root {
        color: white !important;
    }

    .MuiButton-containedPrimary {
        background-color: #266ef1 !important;
    }

    .MuiButton-containedPrimary:hover,
    .MuiButton-containedPrimary:focus {
        background-color: #bb86fc !important;
    }

    // 按钮样式
    .button-group button {
        &.active {
            background-color: #d14991 !important;
            color: #fff !important;
            border-color: #d14991 !important;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.08) !important;
        }
    }

    // 订单支付部分
    .order-info {
        background-color: #202c52;
        color: #fff;
        padding: 16px;
        border-radius: 8px;

        .order-header,
        .order-footer {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }

        .qr-code {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;

            .copy-button {
                margin-top: 16px;
            }

            .copy-success {
                margin-top: 8px;
                color: #4caf50;
            }
        }

        .warning {
            color: #ffc107;
            font-weight: bold;
        }
    }
}